/* scrollbar */
figure.shiki div.codeblock::-webkit-scrollbar-thumb {
  background: var(--hlscrollbar-bg);
  border-radius: 2.5px;
}
figure.shiki div.codeblock::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/* code block figure */
figure.shiki .shiki-tools.closed ~ * {
  display: none;
}
figure.shiki div.codeblock {
  margin: 0;
}
figure.shiki {
  margin: 0 0 24px;
  border-radius: 7px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  position: relative;
  overflow: auto;
  padding: 0;
  background: var(--hl-bg);
  color: var(--hl-color);
  line-height: 1.6;
}
/* highlighttool bar */
.shiki-tools {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  overflow: hidden;
  min-height: 24px;
  height: 2.15em;
  background: var(--hltools-bg);
  color: var(--hltools-color);
  font-size: 1em;
  user-select: none;
}
figure.shiki .shiki-tools .expand {
  right: 0;
  position: absolute;
  padding: 0.57em 0.7em;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  -o-transition: -o-transform 0.3s;
  -ms-transition: -ms-transform 0.3s;
  transition: transform 0.3s;
}

figure.shiki .shiki-tools .expand.closed {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
figure.shiki .shiki-tools .code-lang {
  left: 75px;
  position: absolute;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.15em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

figure.shiki .shiki-tools .expand ~ .copy-notice {
  right: 3.45em;
  position: absolute;
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -ms-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

figure.shiki .shiki-tools .expand ~ .copy-button {
  right: 2.1em;
  position: absolute;
  cursor: pointer;
  -webkit-transition: color 0.2s;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -ms-transition: color 0.2s;
  transition: color 0.2s;
}
.shiki-tools .copy-button:hover {
  color: #49b1f5;
}
figure.shiki .shiki-tools:after {
  position: absolute;
  left: 14px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #fc625d;
  -webkit-box-shadow: 20px 0 #fdbc40, 40px 0 #35cd4b;
  box-shadow: 20px 0 #fdbc40, 40px 0 #35cd4b;
  content: " ";
}
/* bottom toolbar */
.code-expand-btn {
  position: absolute;
  display: flex;
  bottom: 0;
  z-index: 10;
  width: 100%;
  background: var(--hlexpand-bg);
  justify-content: center;
  align-items: center;
  font-size: 1em;
  cursor: pointer;
}
@-moz-keyframes code-expand-key {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 0.6;
  }
}

@-webkit-keyframes code-expand-key {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 0.6;
  }
}

@-o-keyframes code-expand-key {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 0.6;
  }
}

@keyframes code-expand-key {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 0.6;
  }
}
.code-expand-btn i {
  padding: 6px 0;
  color: var(--hlnumber-color);
  -webkit-animation: code-expand-key 1.2s infinite;
  -moz-animation: code-expand-key 1.2s infinite;
  -o-animation: code-expand-key 1.2s infinite;
  -ms-animation: code-expand-key 1.2s infinite;
  animation: code-expand-key 1.2s infinite;
}
.code-expand-btn.expand-done > i {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
/* codeblock */
figure.shiki div.codeblock {
  display: block;
  overflow: auto;
  border: none;
  display: flex;
}
figure.shiki div.codeblock div {
  padding: 0;
  border: none;
}
figure.shiki .gutter pre {
  padding-right: 10px !important;
  padding-left: 10px !important;
  background-color: var(--hlnumber-bg) !important;
  color: var(--hlnumber-color) !important;
  text-align: right !important;
  user-select: none !important;
}
figure.shiki pre {
  margin: 0 !important;
  padding: 8px 0 !important;
  border: none !important;
}
figure.shiki pre code {
  background: none !important;
}
figure.shiki .codeblock pre * {
  font-size: 1em;
  font-family: Consolas, "Fira Code", "Fira Mono", Menlo, "DejaVu Sans Mono",
    monospace, 宋体;
  overflow: auto !important;
  line-height: 1.6;
}
figure.shiki .code pre {
  padding-right: 10px !important;
  padding-left: 10px !important;
  width: 100% !important;
  background: none !important;
}
.code-expand-btn:not(.expand-done) ~ pre,
.code-expand-btn:not(.expand-done) ~ *pre {
  overflow: hidden;
}
.code-expand-btn.expand-done + pre,
.code-expand-btn.expand-done + * pre,
.code-expand-btn.expand-done + div.codeblock,
.code-expand-btn.expand-done + * div.codeblock {
  margin-bottom: 1.8em;
}
